import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Employee } from '@app/models/employee/employee.model';
import { PayrollHourType } from '@app/modules/time-tracking/interfaces/settings.interface';
import { TimeTrackingResources } from '../time-tracking.resource';

@Injectable({ providedIn: 'root' })
export class EmployeeHourTypesService {
    constructor(private http: HttpClient) {}

    /**
     * getHourTypes will return an array of hour types that are specific to the employee and the day
     */
    async getHourTypes(employee: Employee, date: string): Promise<PayrollHourType[]> {
        const url = `${TimeTrackingResources.EmployeeHourTypeCheck}?employee_id=${employee.id}&date=${date}`;
        return this.http.get<PayrollHourType[]>(url).toPromise();
    }
}
